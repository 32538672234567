export const common = [
	// -- General -- //
	{ en: 'Submit', 'zh-tw': '提交' },
	{ en: 'Confirm', 'zh-tw': '確認' },
	{ en: 'Cancel', 'zh-tw': '取消' },
	{ en: 'Search', 'zh-tw': '搜索' },
	{ en: 'More info', 'zh-tw': '更多信息' },
	{ en: 'Welcome', 'zh-tw': '歡迎' },
	{ en: 'Date', 'zh-tw': '日期' },
	{ en: 'History', 'zh-tw': '歷史' },
	{ en: 'Type', 'zh-tw': '輸入' },
	{ en: 'User', 'zh-tw': '用戶' },
	{ en: 'Status', 'zh-tw': '狀態' },
	{ en: 'Not named', 'zh-tw': '未命名' },
	{ en: '{count} orders', 'zh-tw': '{count} 訂單' },
	{ en: '{count} items', 'zh-tw': '{count} 物品' },
	{ en: 'kg', 'zh-tw': '公斤' },
	{ en: 'Tax', 'zh-tw': '稅' },
	{ en: 'Created by', 'zh-tw': '創建者' },
	{ en: 'Created at', 'zh-tw': '創建日期' },
	{ en: 'Created date', 'zh-tw': '創建日期' },
	{ en: 'Note', 'zh-tw': '備註' },
	{ en: 'Comment', 'zh-tw': '評論' },
	{ en: 'Finish', 'zh-tw': '完成' },
	{ en: 'Go back', 'zh-tw': '返回' },
	{ en: 'Yes', 'zh-tw': '是' },
	{ en: 'No', 'zh-tw': '否' },
	{ en: 'True', 'zh-tw': '真' },
	{ en: 'False', 'zh-tw': '假' },
	{ en: 'OK', 'zh-tw': '好' },
	{ en: 'Clear', 'zh-tw': '清除' },
	{ en: 'Sort Order', 'zh-tw': '排序順序' },
	{ en: 'Sort', 'zh-tw': '排序' },
	{ en: 'Filter', 'zh-tw': '篩選' },
	{ en: 'All', 'zh-tw': '全部' },

	// Errors
	{
		en: 'Something went wrong, please try again later.',
		'zh-tw': '發生錯誤。請稍後再試。',
	},
	{
		en: 'An unknown error occured during this operation, please contact engineering.',
		'zh-tw': '操作期間發生未知錯誤，請聯繫工程部。',
	},

	// Fields
	{ en: 'Barcode', 'zh-tw': '條碼' },
	{ en: 'Scan barcode', 'zh-tw': '掃描條碼' },
	{ en: 'Enter barcode', 'zh-tw': '輸入條碼' },
	{ en: 'Quantity', 'zh-tw': '數量' },
	{ en: 'Enter quantity', 'zh-tw': '輸入數量' },
	{ en: 'items', 'zh-tw': '物品' },
	// -- Inventory -- //
	{ en: 'No inventory found', 'zh-tw': '無庫存' },
	{ en: 'On hand', 'zh-tw': '庫存' },
	{ en: 'Total Quantity', 'zh-tw': '總數量' },
	{ en: 'Scan bin barcode', 'zh-tw': '掃描裝箱條碼' },
	{ en: 'In transit', 'zh-tw': '在途' },

	// -- Data Types -- //
	// Orders:
	{ en: 'Order', 'zh-tw': '訂單' },
	{ en: 'Order {orderNumber}', 'zh-tw': '訂單 {orderNumber}' },
	{ en: 'Orders', 'zh-tw': '訂單' },
	{ en: 'Scan order barcode', 'zh-tw': '掃描訂單條碼' },
	{ en: 'Order number', 'zh-tw': '訂單號碼' },
	{ en: 'Loading order', 'zh-tw': '正在載入訂單' },
	{ en: 'Loading order {orderNumber}', 'zh-tw': '正在載入訂單 {orderNumber}' },
	{ en: 'Order not found', 'zh-tw': '未找到訂單' },
	{ en: 'Order {orderNumber} not found', 'zh-tw': '未找到訂單 {orderNumber}' },
	{ en: 'Enter order number', 'zh-tw': '輸入訂單號碼' },
	{ en: 'Error updating order', 'zh-tw': '更新訂單時發生錯誤' },
	{ en: 'Error loading order {orderNumber}', 'zh-tw': '無法加載{orderNumber}的訂單訊息' },

	// Order Line Items:
	{ en: 'Refund', 'zh-tw': '退款' },
	{ en: 'Refunded', 'zh-tw': '已退款' },
	{ en: 'Refunded quantity', 'zh-tw': '退貨數量' },

	// Packing
	{ en: 'Packed', 'zh-tw': '已打包' },
	{ en: 'Packed By', 'zh-tw': '由誰打包' },
	{ en: 'Packed Date', 'zh-tw': '打包日期' },
	{ en: 'Packed quantity', 'zh-tw': '打包數量' },

	// Picking
	{ en: 'Picked', 'zh-tw': '已撿貨' },
	{ en: 'Picked quantity', 'zh-tw': '拣貨數量' },

	// Products:
	{ en: 'Scan item barcode', 'zh-tw': '掃描品項條碼' },
	{ en: 'Scan item', 'zh-tw': '扫描物品' },
	{ en: 'Error loading product', 'zh-tw': '無法加載產品' },
	{ en: 'Size', 'zh-tw': '尺寸' },
	{ en: 'Color', 'zh-tw': '顏色' },
	{ en: 'Select variant', 'zh-tw': '選擇差異' },
	{ en: 'Tag Price', 'zh-tw': '標價' },
	{ en: 'Product Margin', 'zh-tw': '商品利潤' },
	{ en: 'Bulk Discount', 'zh-tw': '部分折扣' },
	{ en: 'Rack Discount', 'zh-tw': '架上折扣' },
	{ en: 'Text Name', 'zh-tw': '物品名稱' },
	{ en: 'Overriding Sales Price', 'zh-tw': '覆蓋銷售價格' },
	{ en: 'No product found for barcode {barcode}', 'zh-tw': '未找到條碼 {barcode} 的產品' },

	// Inventory Locations:
	{ en: 'Bin', 'zh-tw': '打包' },
	{ en: 'Scan bin barcode', 'zh-tw': '掃描裝箱條碼' },

	// Printers:
	{ en: 'Printer', 'zh-tw': '出單者' },
	{ en: 'Select printer', 'zh-tw': '選擇印單者' },
	{ en: 'Label type', 'zh-tw': '標籤類型' },
	{ en: 'Select label type', 'zh-tw': '選擇標籤類型' },
	{ en: 'Print label', 'zh-tw': '打印標籤' },
	{ en: 'Print barcode', 'zh-tw': '打印條碼' },

	// Fulfillment
	{ en: 'Fulfillment Request', 'zh-tw': '履行請求' },
	{ en: 'Fulfillment Requests', 'zh-tw': '履行请求' },
	{ en: 'Select Fulfillment Request', 'zh-tw': '選擇履行請求' },

	// Brands + Merchants
	{ en: 'Brand', 'zh-tw': '品牌' },
	{ en: 'Select Brand', 'zh-tw': '選擇品牌' },
	{ en: 'Merchant', 'zh-tw': '貿易商' },
	{ en: 'Select Merchant', 'zh-tw': '選擇商戶' },
	{ en: 'Filter Brand', 'zh-tw': '按品牌過濾' },
	{ en: 'Filter Merchant', 'zh-tw': '按商戶過濾' },

	// Livestream
	{ en: 'Stream', 'zh-tw': '直播' },
	{ en: 'Streamers', 'zh-tw': '直播主' },

	// Boxes:
	{ en: 'Box', 'zh-tw': '箱子' },
	{ en: 'Boxes', 'zh-tw': '箱子' },
	{ en: 'Box Barcode', 'zh-tw': '箱形條碼' },
	{ en: 'Loading box', 'zh-tw': '正在載入箱子' },
	{ en: 'Error loading box', 'zh-tw': '無法加載箱子' },
	{ en: 'Box not found', 'zh-tw': '未找到箱子' },
	{ en: 'Scan box barcode', 'zh-tw': '掃描箱子條碼' },

	// Receipts
	{ en: 'Receipt', 'zh-tw': '收據' },
	{ en: 'Receipts', 'zh-tw': '收據' },

	// Tracking Numbers
	{ en: 'Tracking Number', 'zh-tw': '追蹤號碼' },
	{ en: 'Tracking Numbers', 'zh-tw': '追蹤號碼' },

	// Shipments
	{ en: 'Shipment', 'zh-tw': '出貨' },

	// Months
	{ en: 'January', 'zh-tw': '一月' },
	{ en: 'February', 'zh-tw': '二月' },
	{ en: 'March', 'zh-tw': '三月' },
	{ en: 'April', 'zh-tw': '四月' },
	{ en: 'May', 'zh-tw': '五月' },
	{ en: 'June', 'zh-tw': '六月' },
	{ en: 'July', 'zh-tw': '七月' },
	{ en: 'August', 'zh-tw': '八月' },
	{ en: 'September', 'zh-tw': '九月' },
	{ en: 'October', 'zh-tw': '十月' },
	{ en: 'November', 'zh-tw': '十一月' },
	{ en: 'December', 'zh-tw': '十二月' },
];
